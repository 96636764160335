// const { algoliasearch, instantsearch } = window;
import instantsearch from "instantsearch.js/dist/instantsearch.production.min";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { debounce } from 'lodash';
import jQuery from 'jquery';
import * as bootstrap from 'bootstrap';
import { connectRefinementList } from 'instantsearch.js/es/connectors';

import {
  searchBox,
  pagination,
  refinementList,
  hits,
  stats,
  sortBy,
  hierarchicalMenu,
  rangeInput,
  rangeSlider,
  ratingMenu,
  toggleRefinement,
  hitsPerPage,
  clearRefinements,
  configure
} from 'instantsearch.js/es/widgets';

const publicIpAddress = process.env.PUBLIC_IP_ADDRESS || 'nnade.com';

window.$ = jQuery; // workaround for https://github.com/parcel-bundler/parcel/issues/333



// const searchClient = algoliasearch('latency', 'xyz');

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "xyz", // Be sure to use the search-only-api-key
    nodes: [
      {
        host: publicIpAddress,
        port: "8108",
        protocol: "https"
      }
    ]
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: "name,description,brand"
  }
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

// const refinementList1 = instantsearch.widgets.connectRefinementList();
// const refinementList2 = instantsearch.widgets.connectRefinementList();


// 1. Instantiate the search
const search = instantsearch({
  searchClient,
  indexName: "products",
  routing: true,  //added
});

// =============== Begin Widget Configuration


search.addWidgets([
  searchBox({
    container: '#searchbox',
    showSubmit: false,
    showReset: false,
    placeholder: 'Search by Part Number or Part Name... ',
    autofocus: false,
    cssClasses: {
      input: 'form-control form-control-sm border border-light text-dark',
      loadingIcon: 'stroke-primary',
    },
  }),
  pagination({
    container: '#pagination',
    cssClasses: {
      list: 'd-flex flex-row justify-content-end',
      item: 'px-2 d-block',
      link: 'text-decoration-none',
      disabledItem: 'text-muted',
      selectedItem: 'fw-bold text-primary',
    },
  }),
  refinementList({
    limit: 10,
    showMoreLimit: 50,
    container: '#brand-list',
    attribute: 'brand',
    searchable: true,
    searchablePlaceholder: 'Search brands',
    showMore: true,
    sortBy: ['name:asc', 'count:desc'],
    cssClasses: {
      searchableInput:
        'form-control form-control-sm form-control-secondary mb-2 border-light-2',
      searchableSubmit: 'd-none',
      searchableReset: 'd-none',
      showMore: 'btn btn-secondary btn-sm',
      list: 'list-unstyled',
      count: 'badge text-dark-2 ms-2',
      label: 'd-flex align-items-center',
      checkbox: 'me-2',
    },
  }),

  // refinementList2({
  //   limit: 10,
  //   showMoreLimit: 50,
  //   container: '#location-list',
  //   attribute: 'location',
  //   searchable: true,
  //   searchablePlaceholder: 'Search locations',
  //   showMore: true,
  //   sortBy: ['name:asc', 'count:desc'],
  //   cssClasses: {
  //     searchableInput:
  //       'form-control form-control-sm form-control-secondary mb-2 border-light-2',
  //     searchableSubmit: 'd-none',
  //     searchableReset: 'd-none',
  //     showMore: 'btn btn-secondary btn-sm',
  //     list: 'list-unstyled',
  //     count: 'badge text-dark-2 ms-2',
  //     label: 'd-flex align-items-center',
  //     checkbox: 'me-2',
  //   },
  // }),
  hierarchicalMenu({
    container: '#categories-hierarchical-menu',
    showParentLevel: true,
    rootPath: 'Spare Parts',
    attributes: [
      'categories'
      // 'categories.lvl0',
      // 'categories.lvl1',
      // 'categories.lvl2',
      // 'categories.lvl3',
    ],
    cssClasses: {
      showMore: 'btn btn-secondary btn-sm',
      list: 'list-unstyled',
      childList: 'ms-4',
      count: 'badge text-dark-2 ms-2',
      link: 'text-dark text-decoration-none',
      selectedItem: 'text-primary fw-bold',
      parentItem: 'text-dark fw-bold',
    },
  }),
  // toggleRefinement({
  //   container: '#toggle-refinement',
  //   attribute: 'free_shipping',
  //   templates: {
  //     labelText: 'Free shipping',
  //   },
  //   cssClasses: {
  //     label: 'd-flex align-items-center',
  //     checkbox: 'me-2',
  //   },
  // }),
  // rangeInput({
  //   container: '#price-range-slider',
  //   attribute: 'price',
  // }),
  rangeSlider({
    container: '#price-range-slider',
    attribute: 'price',
  }),
  // ratingMenu({
  //   container: '#rating-menu',
  //   attribute: 'rating',
  //   cssClasses: {
  //     list: 'list-unstyled',
  //     link: 'text-decoration-none',
  //     starIcon: '',
  //     count: 'badge text-dark-2 ms-2',
  //     disabledItem: 'text-muted',
  //     selectedItem: 'fw-bold text-primary',
  //   },
  // }),
  sortBy({
    container: '#sort-by',
    items: [
      { label: 'Relevancy', value: 'products' },
      { label: 'Price (asc)', value: 'products/sort/price:asc' },
      { label: 'Price (desc)', value: 'products/sort/price:desc' },
    ],
    cssClasses: {
      select: 'form-select form-select-sm border-none text-black',
    },
  }),
  hits({
    container: '#hits',
    templates: {
      item: (hit, { html, components }) => html`
        <a href="/api/item/${hit.post_id}">
          <div>
            <div class="row image-container">
              <div class="col-md d-flex align-items-end justify-content-center">
                <img src="data:image/png;base64,${hit.image}" alt="${hit.name}" width="100%" height="100%" object-fit="cover" />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md items-md">
                <h3>${components.Highlight({ hit, attribute: 'name' })}</h3>
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col-md items-md">
                <h5>${components.Highlight({ hit, attribute: 'description' })}</h5>
              </div>
            </div>
  
            <div class="row mt-auto">
              <div class="col-md items-md">
                <h5>
                  <div class="hit-price fw-bold mt-4">\GHS ${hit.currency}</div>
                </h5>
                <div class="hit-price fw-bold mt-4 click" style="text-align: center !important">
                  \click on image to see more details.
                </div>
              </div>
            </div>
          </div>
        </a>
      `,
    },
    cssClasses: {
      list: 'list-unstyled grid-container',
      item: 'd-flex flex-column search-result-card mb-1 me-1 p-3',
      loadMore: 'btn btn-primary mx-auto d-block mt-4',
      disabledLoadMore: 'btn btn-dark mx-auto d-block mt-4',
    },
  }),

  // configure({
  //   container: '#hits-per-page',
  //   hitsPerPage: [
  //     { label: '9 per page', value: 9 },
  //     { label: '18 per page', value: 18 },
  //   ],
  //   cssClasses: {
  //     root: 'custom-hits-per-page',
  //     select: 'form-select form-select-sm border-none text-black',
  //   },
  // }),

  hitsPerPage({
    container: '#hits-per-page',
    items: [
      { label: '9 per page', value: 9, default: true },
      { label: '18 per page', value: 18 },
    ],
    cssClasses: {
      select: 'form-select form-select-sm border-none text-black',
    },
  }),
  stats({
    container: '#stats',
    templates: {
      text: `
      {{#hasNoResults}}No products{{/hasNoResults}}
      {{#hasOneResult}}1 product{{/hasOneResult}}
      {{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} products{{/hasManyResults}}
      found in {{processingTimeMS}}ms
    `,
    },
    cssClasses: {
      text: 'small',
    },
  }),
  clearRefinements({
    container: '#clear-refinements',
    cssClasses: {
      button: 'btn btn-primary',
    },
  }),
]);

window.sendEventDebounced = debounce((uiState) => {
  window.gtag('event', 'page_view', {
    page_path: window.location.pathname + window.location.search,
  });
}, 500);

search.use(() => ({
  onStateChange({ uiState }) {
    window.sendEventDebounced(uiState);
  },
  subscribe() {},
  unsubscribe() {},
}));



search.start();



